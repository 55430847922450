// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("trix")
require("@rails/actiontext")

jQuery(document).ready(function(){

  jQuery('html').toggleClass('js');

  jQuery('.menu_toggle').click(function(e){
    e.preventDefault();
    jQuery(this).toggleClass('menu_toggle--active');
    jQuery('.header__nav').toggleClass('header__nav--active');
    jQuery('.main_nav').toggleClass('main_nav--active');
    jQuery('.tra_header').removeClass('tra_header--open');
    jQuery('.tra_header__toggle').removeClass('tra_header__toggle--on');
  });

  /* Reading Agency Global Header */

  jQuery('.tra_header__programme_list').insertAfter('.tra_header__logo');

  jQuery('.tra_header__toggle').click(function(e) {
    e.preventDefault();
    jQuery('.tra_header').toggleClass('tra_header--open');
    jQuery(this).toggleClass('tra_header__toggle--on');
  });

  /* Sharing Stories Carousel */

  jQuery('.sharing_stories__carousel').slick({
    dots: false,
    arrows: false,
    infinite: true,
    speed: 300,
    rows: 0,
    slidesToShow: 4,
    slidesToScroll: 4,
    centerPadding: '16px',
    responsive: [
      {
        breakpoint: 944,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 620,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  });

  /* Video Carousel */

  jQuery('.video_carousel').slick({
    dots: true,
    arrows: true,
    infinite: false,
    adaptiveHeight: true,
    rows: 0,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 840,
        settings: {
          arrows: false
        }
      }
    ]
  });

  /* Mailing list lightbox */

  // Add class to any link with the href 'mailing-list'
  var mailing = '#mailing-list';
  jQuery('a').each(function(){
     var link = $(this).attr('href');
     if (mailing == link){
       $(this).addClass('mailing_list_popup_toggle');
     }
  }); 

  jQuery('.mailing_list_popup_toggle').click(function(e){
    e.preventDefault();
    jQuery(window).scrollTop(0);
    jQuery('.mailing_list_popup').fadeToggle();
  });

  jQuery('.mailing_list_popup__close').click(function(){
    jQuery(this).parent().fadeToggle();
  });

  /* Resgister interest lightbox */

  // Add class to any link with the href 'register'
  var register = '#register';
  jQuery('a').each(function(){
     var link = $(this).attr('href');
     if (register == link){
       $(this).addClass('register_interest_form_popup_toggle');
     }
  }); 

  jQuery('.register_interest_form_popup_toggle').click(function(e){
    e.preventDefault();
    jQuery(window).scrollTop(0);
    jQuery('.register_interest_form_popup').fadeToggle();
  });

  jQuery('.register_interest_form_popup__close').click(function(){
    jQuery(this).parent().fadeToggle();
  });

  /* Toolkit */

  // Main nav menu

  jQuery('.header__navigation').before('<span class="header__navigation-toggle"><div></div></span>');

  jQuery('.header__navigation-toggle').click(function(){
    jQuery('.header__navigation').toggleClass('header__navigation--show');
    jQuery(this).toggleClass('header__navigation-toggle--active');
  });

  // Contents navigation

  jQuery('.contents__toggle').click(function(){
    jQuery('.contents .menu').slideToggle();
    jQuery(this).toggleClass('contents__toggle--active');
  });

  // Checklist show hide

  jQuery('.checklist-item').click(function(){
    jQuery(this).toggleClass('checklist-item--active');
    jQuery(this).children('.checklist-item__content').slideToggle();
  });

  // Section show hide

  jQuery('<span class="entry_section__toggle"></span>').appendTo('.entry_section__title');

  // Auto hide content
  jQuery('.entry_section__title').children('.entry_section__toggle').addClass('entry_section__toggle--hide');
  jQuery('.entry_section__title').siblings('.editor').slideToggle();

  // Show hide click event
  jQuery('.entry_section__title').click(function(){
    jQuery(this).children('.entry_section__toggle').toggleClass('entry_section__toggle--hide');
    jQuery(this).siblings('.editor').slideToggle();
  });

  // Volunteer journey infographic

  jQuery('.volunteer-journey-infographic > ul > li > ul > li > h4').click(function(){
    jQuery(this).toggleClass('active');
    jQuery(this).siblings('p').slideToggle();
  });

  // Alert Dismiss

  $(document).on('ready turbolinks:load', function() {
    console.log('Ready');
  
    $('.alert').on('click', 'button', function(e){
      $(this).parent().fadeOut();
    });
  
  });

});